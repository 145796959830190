.contact a{
    margin-right:10px;
}
.contact a svg{
    margin-right:6px;
}

.contact h3{
    margin-bottom:10px;
    margin-top:24px;
}
.skilllist{
    margin-bottom:calc(var(--global-layout-pad) * 2);
    list-style-type: square;
    padding-left:1em;
}
.skilllist li{
    margin:var(--global-element-pad) 0 0 0;
    font-size:var(--font-size-1);
    line-height: calc(var(--font-size-1)*1.5);
}