@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;800&family=Raleway:wght@300;400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;800&family=Raleway:wght@300;400;700;800&display=swap);
:root {
    --color-hilite: rgb(239, 113, 223);
    --color-grey: #ccc;
    --color-black: #333333;
    --color-white: #ffffff;
    --global-element-pad: 20px;
    --global-content-pad: 40px;
    --global-layout-pad:50px;
    --global-layout-pad-total:calc(var(--global-layout-pad) * 2);

    --serif-font: 'Playfair Display', serif;
    --sans-font: 'Raleway', sans-serif;


    /* layout */
    --nav-width:35vw;
    --content-width:65vw;
    --content-rightpad:20vw;
    --content-rightpad-extra:10vw;
    --header-height:62px;
    --window-height:768px;
    --negative-window-height:calc(0 - var(--window-height));

    --font-size-0: 1rem; /*16px*/
    --font-size-1: 1.125rem; /*18px*/
    --font-size-2: 1.5rem; /*24px*/
    --font-size-3: 2.25rem; /*36px*/
    --font-size-4: 3.375rem; /*54px*/
    --font-size-5: 5.063rem; /*81px*/
    --font-size-6: 7.594rem; /*121.5px*/

    --nav-font-size:calc(20px + (120 - 20) * ((100vw - 700px) / (1600 - 600)));
    --nav-line-height:calc(24px + (104 - 24) * ((100vw - 600px) / (1600 - 600)));
    --nav-desktop-closed:calc((var(--window-height) - (var(--header-height) + var(--global-layout-pad-total))));
    --mobile-breakpoint:900px;
  
}

@media screen and (max-width: 1440px) { 
  :root {
    --content-rightpad-extra:5vw;
     --nav-width:30vw;
     --content-width:70vw;
 }
}
@media screen and (max-width: 1200px) { 
  :root {
    --content-rightpad-extra:1vw;
    --nav-width:25vw;
    --content-width:75vw;
   }
}


@media screen and (max-width: 900px) { /*basically mobile*/
     :root {
      --global-element-pad: 10px;
      --global-content-pad: 20px;
      --global-layout-pad:30px;

      
        --nav-width:20vw;
        --content-width:calc(100vw - var(--global-element-pad));
        --content-rightpad-extra:0vw;



        --font-size-1: 1rem; /*16px*/
        --font-size-2: 1.125rem; /*18px*/
        --font-size-3: 1.5rem; /*24px*/
        --font-size-4: 2.25rem; /*36px*/
        --font-size-5: 3.375rem; /*54px*/
        --font-size-6: 5.063rem; /*81px*/
        --font-size-7: 7.594rem; /*121.5px*/

        --nav-font-size: var(--font-size-6);
        --nav-line-height: calc(var(--font-size-6) * .9);
    }
}


/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

body {
    font-family: var(--sans-font);
    background: var(--color-black);
    color:var(--color-white);
    padding:var(--global-layout-pad) var(--global-layout-pad) 0 var(--global-layout-pad);
    margin:0;
}

p{
    font-size:var(--font-size-1);
    line-height: calc(var(--font-size-1)*2);
}
a{
    color:inherit;
    opacity:.35;
    transition: all .2s linear;   
}
a.global_image-link__3YVMm{
    opacity:1;
}
a.global_image-link__3YVMm:hover{
    opacity:0.75;
}
h2,h3{
    font-weight: 500;
}
h2{
    font-size:var(--font-size-3);
}
h3{
    font-size:var(--font-size-2);
}
a:hover{
    opacity:1;
}
.global_uilink__3LOUH{
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border:0;
    background:none;
    padding:0;
    margin:0;
    cursor: pointer;
    transition: all .25s linear;
    color:var(--color-white);
    font-size: inherit;
}
.global_App__2XcQ2{
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
}

.global_section-wrapper__3Qx54{
    width: calc(var(--content-width) - var(--global-layout-pad-total));
    /* overflow-x: hidden; */
    position: relative;
    /* min-height: 75vh; */
    flex-grow: 3;
    align-self: flex-end;
    margin-top: var(--header-height);
}
.global_section-wrapper__3Qx54.global_on-entering__30c8Z{
    overflow: hidden;
    min-height:calc(100vh - (var(--header-height) + var(--global-layout-pad)));
}

.global_section-inner-wrapper__RKYTF{
    position: absolute;
    width:calc(100% - (var(--content-rightpad) + var(--content-rightpad-extra)));
    padding-top:calc(10vh);
}

.global_section-enter__36tgT {
    transform: translateX(134%);
}

.global_section-enter-active__2bUqp {
    transform: translateX(0%);
    transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.global_section-exit__3i3vd {
    transform: translateX(0%);
}

.global_section-exit-active__19R4a {
    transform: translateX(-134%);
    transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.global_sr-only__GTdAB{
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}
.Nav_wrapper__39EzM{
    width:100%;
}

.Nav_wrapper__39EzM h1{
    display:inline-block;
    margin:0;
    font-size:var(--font-size-4);
    font-weight: 800;
    opacity:1;
    transition: all .35s linear;   
}
a.Nav_selected__dL1UN{
    opacity:1;
}

.Nav_header__wHv4J{
    position: fixed;
    width: calc(100vw - (var(--global-layout-pad) * 2));
    z-index: 1;
}

.Nav_social__1S7JZ{
    list-style:none;
    margin:0;
    padding:0;
    float:right;
    display: flex;
    height: var(--font-size-4);
    align-items: center;
}
.Nav_social__1S7JZ li{
    display:inline-block;
    padding:0;
    margin: 0 14px 0 0;
}

.Nav_wrapperCollapsed__1KDOc .Nav_mobileNav__3wU7T{
    width: var(--font-size-4);
}
.Nav_wrapperCollapsed__1KDOc .Nav_mobileNav__3wU7T button{
    display:block;
}

.Nav_mobileNav__3wU7T svg{
    padding:10px 10px 10px 0;
    transition: all .65s cubic-bezier(0.19, 1, 0.22, 1); 
}

.Nav_mobileNav__3wU7T{
    width:0;
    display:inline-block;
    vertical-align: baseline;
    transition: all .65s cubic-bezier(0.19, 1, 0.22, 1); 
    overflow: hidden;
}
.Nav_mobileNav__3wU7T button{
    display:none;
    width: var(--font-size-4);
}

.Nav_mobileNav__3wU7T .Nav_closeBtn__2Y9pl{
    opacity: 0;
    position: absolute;
    left:10px;
    top:13px;
}
.Nav_wrapperCollapsed__1KDOc.Nav_openNav__38DIj .Nav_mobileNav__3wU7T svg{
    transform:rotate(90deg);
}

.Nav_wrapperCollapsed__1KDOc.Nav_openNav__38DIj .Nav_mobileNav__3wU7T .Nav_openBtn__fIcl1{
    opacity: 0;
}
.Nav_wrapperCollapsed__1KDOc.Nav_openNav__38DIj .Nav_mobileNav__3wU7T .Nav_closeBtn__2Y9pl{
    opacity: 1;
} 
.Nav_wrapperCollapsed__1KDOc .Nav_nav__3gICw{
    position:fixed;
    transform:translateY(calc(var(--nav-desktop-closed) - calc(var(--nav-desktop-closed) * 2)));
    transition: all .65s cubic-bezier(0.19, 1, 0.22, 1);
    height: 1px;
    overflow: hidden;
}

.Nav_wrapperCollapsed__1KDOc.Nav_openNav__38DIj .Nav_nav__3gICw{
    display: block;
    position:fixed;
    transform:translateY(0px);
    height: calc(var(--nav-line-height) * 3);
}

.Nav_nav__3gICw{
    list-style:none;
    margin:0;
    padding:0;
    position: absolute;
    bottom:var(--global-layout-pad);
    width:var(--nav-width);
    line-height: var(--nav-line-height);
    font-size: var(--nav-font-size);
}
.Nav_nav__3gICw a{
    display:block;
}
.Nav_navtracker__3EGcR{
    position: absolute;
    width:1px;
    height:1px;
    bottom: calc((var(--nav-line-height) * 3) + var(--global-layout-pad));
}
.Nav_nav__3gICw li{
    margin:0;
    padding:0;
}
.Nav_nav__3gICw a{
    text-decoration: none;
    font-weight: 800;
    opacity:0.35;
    transition: all .35s linear;   
}
.Nav_nav__3gICw a:hover{
    opacity:1;
}
.Nav_selected__dL1UN a{
    opacity:1;
}

.Nav_uilink__ENYTO{
    width:100%;
}
@media screen and (max-width: 900px) { 
    .Nav_wrapper__39EzM h1{
        vertical-align: top;
    }
    .Nav_mobileNav__3wU7T .Nav_closeBtn__2Y9pl{
        top:calc(var(--global-layout-pad) + 2px);
        left:var(--global-layout-pad);
        padding:10px 10px 0px 10px;
    }
    
    .Nav_header__wHv4J{
        background: var(--color-black);
        margin: calc(0px - var(--global-layout-pad));
        z-index: 100;
        padding-top: var(--global-layout-pad);
        padding-right: var(--global-layout-pad);
        padding-left: var(--global-layout-pad);
        padding-bottom: calc(var(--global-layout-pad) / 2);
    }

    .Nav_wrapperCollapsed__1KDOc .Nav_nav__3gICw{
        padding-top:0px;
        padding-bottom:0px;
        width:100%;
        position:fixed;
        transform:translateY(calc(0px - var(--window-height)));
        bottom: 0px;
        transition: all .65s cubic-bezier(0.19, 1, 0.22, 1);
        height: 0px;
        overflow: hidden;
        z-index: 1;
        background: var(--color-black);
    }

    .Nav_wrapperCollapsed__1KDOc.Nav_openNav__38DIj .Nav_nav__3gICw{
        background: var(--color-black);
        z-index: 1;
        width: 100%;
        padding-top: calc(100% + (var(--nav-line-height) * 3));
        padding-bottom: var(--global-element-pad);
        bottom: 0px;
        transform:translateY(0px);
        height: calc((var(--nav-line-height) * 3) + var(--global-element-pad));
        margin-left: calc(0px - var(--global-layout-pad));
        padding-left: var(--global-layout-pad);
    }
    
}
.ContentPanel_contact__3JP8U a{
    margin-right:10px;
}
.ContentPanel_contact__3JP8U a svg{
    margin-right:6px;
}

.ContentPanel_contact__3JP8U h3{
    margin-bottom:10px;
    margin-top:24px;
}
.ContentPanel_skilllist__3fd87{
    margin-bottom:calc(var(--global-layout-pad) * 2);
    list-style-type: square;
    padding-left:1em;
}
.ContentPanel_skilllist__3fd87 li{
    margin:var(--global-element-pad) 0 0 0;
    font-size:var(--font-size-1);
    line-height: calc(var(--font-size-1)*1.5);
}
.Code_codesection__1K7i-{

}

.Code_codesection__1K7i- ul{
    list-style:none;   

    margin:var(--global-content-pad) 0 calc(var(--global-layout-pad) * 2) 0;
    padding:0;
}

.Code_codepenImage__2h-l2{
    opacity: 0.5;
    position: relative;
    max-width: 100%;
    display: block;
    
}

.Code_worklist__2WT8w li{
    margin-bottom:28px;
    /* display: flex; */
    
}
.Code_workitemImage__2DpxH{
    width:100%;
    background:var(--color-grey);
    margin:var(--global-layout-pad) 0 var(--global-element-pad) 0; 
    position: relative;
}
.Code_workitemImage__2DpxH img{
    width:calc(100% - 20px);
    max-width: 100%;
    padding:10px;
}
.Code_techstack__XadUj{
    margin-top:calc(var(--global-element-pad)/2);
    padding-top:calc(var(--global-element-pad)/3);
    line-height:calc(var(--font-size-1) * 1.25);
    border-top:1px solid var(--color-grey);
    margin-bottom:calc(var(--global-element-pad)/2);
}

.Code_moreInfo__2FfV6{
    height:0;
    overflow: hidden;
    transition: all .45s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.Code_moreInfo__2FfV6 a{
    display: none;
}

.Code_uilink__3FaT6{
    font-size:14px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}
a.Code_uilink__3FaT6{
    float:right;
}

.Code_uilink__3FaT6 svg {
    display: inline-block;
    margin-left:calc(var(--global-element-pad)/3);
    transition: transform .45s linear;
}
.Code_uilink__3FaT6{
}

button.Code_uilink__3FaT6{
    opacity:0.45;
}
button.Code_uilink__3FaT6:hover{
    opacity:1;
}

button.Code_uilink__3FaT6[data-open="true"] svg{
    transform:rotate(90deg);
}

button.Code_uilink__3FaT6[data-open="true"] + .Code_moreInfo__2FfV6 a{
    display:inline-block;
}


@media screen and (max-width: 900px) { 
    .Code_workitemContent__Jytdr a.Code_uilink__3FaT6:first-of-type{
        display:none;
    }
    
}
:root {
    --color-hilite: rgb(239, 113, 223);
    --color-grey: #ccc;
    --color-black: #333333;
    --color-white: #ffffff;
    --global-element-pad: 20px;
    --global-content-pad: 40px;
    --global-layout-pad:50px;
    --global-layout-pad-total:calc(var(--global-layout-pad) * 2);

    --serif-font: 'Playfair Display', serif;
    --sans-font: 'Raleway', sans-serif;


    /* layout */
    --nav-width:35vw;
    --content-width:65vw;
    --content-rightpad:20vw;
    --content-rightpad-extra:10vw;
    --header-height:62px;
    --window-height:768px;
    --negative-window-height:calc(0 - var(--window-height));

    --font-size-0: 1rem; /*16px*/
    --font-size-1: 1.125rem; /*18px*/
    --font-size-2: 1.5rem; /*24px*/
    --font-size-3: 2.25rem; /*36px*/
    --font-size-4: 3.375rem; /*54px*/
    --font-size-5: 5.063rem; /*81px*/
    --font-size-6: 7.594rem; /*121.5px*/

    --nav-font-size:calc(20px + (120 - 20) * ((100vw - 700px) / (1600 - 600)));
    --nav-line-height:calc(24px + (104 - 24) * ((100vw - 600px) / (1600 - 600)));
    --nav-desktop-closed:calc((var(--window-height) - (var(--header-height) + var(--global-layout-pad-total))));
    --mobile-breakpoint:900px;
  
}

@media screen and (max-width: 1440px) { 
  :root {
    --content-rightpad-extra:5vw;
     --nav-width:30vw;
     --content-width:70vw;
 }
}
@media screen and (max-width: 1200px) { 
  :root {
    --content-rightpad-extra:1vw;
    --nav-width:25vw;
    --content-width:75vw;
   }
}


@media screen and (max-width: 900px) { /*basically mobile*/
     :root {
      --global-element-pad: 10px;
      --global-content-pad: 20px;
      --global-layout-pad:30px;

      
        --nav-width:20vw;
        --content-width:calc(100vw - var(--global-element-pad));
        --content-rightpad-extra:0vw;



        --font-size-1: 1rem; /*16px*/
        --font-size-2: 1.125rem; /*18px*/
        --font-size-3: 1.5rem; /*24px*/
        --font-size-4: 2.25rem; /*36px*/
        --font-size-5: 3.375rem; /*54px*/
        --font-size-6: 5.063rem; /*81px*/
        --font-size-7: 7.594rem; /*121.5px*/

        --nav-font-size: var(--font-size-6);
        --nav-line-height: calc(var(--font-size-6) * .9);
    }
}


/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

body {
    font-family: var(--sans-font);
    background: var(--color-black);
    color:var(--color-white);
    padding:var(--global-layout-pad) var(--global-layout-pad) 0 var(--global-layout-pad);
    margin:0;
}

p{
    font-size:var(--font-size-1);
    line-height: calc(var(--font-size-1)*2);
}
a{
    color:inherit;
    opacity:.35;
    transition: all .2s linear;   
}
a.image-link{
    opacity:1;
}
a.image-link:hover{
    opacity:0.75;
}
h2,h3{
    font-weight: 500;
}
h2{
    font-size:var(--font-size-3);
}
h3{
    font-size:var(--font-size-2);
}
a:hover{
    opacity:1;
}
.uilink{
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border:0;
    background:none;
    padding:0;
    margin:0;
    cursor: pointer;
    transition: all .25s linear;
    color:var(--color-white);
    font-size: inherit;
}
.App{
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
}

.section-wrapper{
    width: calc(var(--content-width) - var(--global-layout-pad-total));
    /* overflow-x: hidden; */
    position: relative;
    /* min-height: 75vh; */
    flex-grow: 3;
    align-self: flex-end;
    margin-top: var(--header-height);
}
.section-wrapper.on-entering{
    overflow: hidden;
    min-height:calc(100vh - (var(--header-height) + var(--global-layout-pad)));
}

.section-inner-wrapper{
    position: absolute;
    width:calc(100% - (var(--content-rightpad) + var(--content-rightpad-extra)));
    padding-top:calc(10vh);
}

.section-enter {
    transform: translateX(134%);
}

.section-enter-active {
    transform: translateX(0%);
    transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.section-exit {
    transform: translateX(0%);
}

.section-exit-active {
    transform: translateX(-134%);
    transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.sr-only{
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}
