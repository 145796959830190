.codesection{

}

.codesection ul{
    list-style:none;   

    margin:var(--global-content-pad) 0 calc(var(--global-layout-pad) * 2) 0;
    padding:0;
}

.codepenImage{
    opacity: 0.5;
    position: relative;
    max-width: 100%;
    display: block;
    
}

.worklist li{
    margin-bottom:28px;
    /* display: flex; */
    
}
.workitemImage{
    width:100%;
    background:var(--color-grey);
    margin:var(--global-layout-pad) 0 var(--global-element-pad) 0; 
    position: relative;
}
.workitemImage img{
    width:calc(100% - 20px);
    max-width: 100%;
    padding:10px;
}
.techstack{
    margin-top:calc(var(--global-element-pad)/2);
    padding-top:calc(var(--global-element-pad)/3);
    line-height:calc(var(--font-size-1) * 1.25);
    border-top:1px solid var(--color-grey);
    margin-bottom:calc(var(--global-element-pad)/2);
}

.moreInfo{
    height:0;
    overflow: hidden;
    transition: all .45s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.moreInfo a{
    display: none;
}

.uilink{
    font-size:14px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}
a.uilink{
    float:right;
}

.uilink svg {
    display: inline-block;
    margin-left:calc(var(--global-element-pad)/3);
    transition: transform .45s linear;
}
.uilink{
    composes: uilink from '../../styles/global.css';
}

button.uilink{
    opacity:0.45;
}
button.uilink:hover{
    opacity:1;
}

button.uilink[data-open="true"] svg{
    transform:rotate(90deg);
}

button.uilink[data-open="true"] + .moreInfo a{
    display:inline-block;
}


@media screen and (max-width: 900px) { 
    .workitemContent a.uilink:first-of-type{
        display:none;
    }
    
}