@import 'vars.css';
@import 'normalize.css';
@import 'fonts.css';

body {
    font-family: var(--sans-font);
    background: var(--color-black);
    color:var(--color-white);
    padding:var(--global-layout-pad) var(--global-layout-pad) 0 var(--global-layout-pad);
    margin:0;
}

p{
    font-size:var(--font-size-1);
    line-height: calc(var(--font-size-1)*2);
}
a{
    color:inherit;
    opacity:.35;
    transition: all .2s linear;   
}
a.image-link{
    opacity:1;
}
a.image-link:hover{
    opacity:0.75;
}
h2,h3{
    font-weight: 500;
}
h2{
    font-size:var(--font-size-3);
}
h3{
    font-size:var(--font-size-2);
}
a:hover{
    opacity:1;
}
.uilink{
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border:0;
    background:none;
    padding:0;
    margin:0;
    cursor: pointer;
    transition: all .25s linear;
    color:var(--color-white);
    font-size: inherit;
}
.App{
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
}

.section-wrapper{
    width: calc(var(--content-width) - var(--global-layout-pad-total));
    /* overflow-x: hidden; */
    position: relative;
    /* min-height: 75vh; */
    flex-grow: 3;
    align-self: flex-end;
    margin-top: var(--header-height);
}
.section-wrapper.on-entering{
    overflow: hidden;
    min-height:calc(100vh - (var(--header-height) + var(--global-layout-pad)));
}

.section-inner-wrapper{
    position: absolute;
    width:calc(100% - (var(--content-rightpad) + var(--content-rightpad-extra)));
    padding-top:calc(10vh);
}

.section-enter {
    transform: translateX(134%);
}

.section-enter-active {
    transform: translateX(0%);
    transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.section-exit {
    transform: translateX(0%);
}

.section-exit-active {
    transform: translateX(-134%);
    transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.sr-only{
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}