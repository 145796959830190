.wrapper{
    width:100%;
}

.wrapper h1{
    display:inline-block;
    margin:0;
    font-size:var(--font-size-4);
    font-weight: 800;
    opacity:1;
    transition: all .35s linear;   
}
a.selected{
    opacity:1;
}

.header{
    position: fixed;
    width: calc(100vw - (var(--global-layout-pad) * 2));
    z-index: 1;
}

.social{
    list-style:none;
    margin:0;
    padding:0;
    float:right;
    display: flex;
    height: var(--font-size-4);
    align-items: center;
}
.social li{
    display:inline-block;
    padding:0;
    margin: 0 14px 0 0;
}

.wrapperCollapsed .mobileNav{
    width: var(--font-size-4);
}
.wrapperCollapsed .mobileNav button{
    display:block;
}

.mobileNav svg{
    padding:10px 10px 10px 0;
    transition: all .65s cubic-bezier(0.19, 1, 0.22, 1); 
}

.mobileNav{
    width:0;
    display:inline-block;
    vertical-align: baseline;
    transition: all .65s cubic-bezier(0.19, 1, 0.22, 1); 
    overflow: hidden;
}
.mobileNav button{
    display:none;
    width: var(--font-size-4);
}

.mobileNav .closeBtn{
    opacity: 0;
    position: absolute;
    left:10px;
    top:13px;
}
.wrapperCollapsed.openNav .mobileNav svg{
    transform:rotate(90deg);
}

.wrapperCollapsed.openNav .mobileNav .openBtn{
    opacity: 0;
}
.wrapperCollapsed.openNav .mobileNav .closeBtn{
    opacity: 1;
} 
.wrapperCollapsed .nav{
    position:fixed;
    transform:translateY(calc(var(--nav-desktop-closed) - calc(var(--nav-desktop-closed) * 2)));
    transition: all .65s cubic-bezier(0.19, 1, 0.22, 1);
    height: 1px;
    overflow: hidden;
}

.wrapperCollapsed.openNav .nav{
    display: block;
    position:fixed;
    transform:translateY(0px);
    height: calc(var(--nav-line-height) * 3);
}

.nav{
    list-style:none;
    margin:0;
    padding:0;
    position: absolute;
    bottom:var(--global-layout-pad);
    width:var(--nav-width);
    line-height: var(--nav-line-height);
    font-size: var(--nav-font-size);
}
.nav a{
    display:block;
}
.navtracker{
    position: absolute;
    width:1px;
    height:1px;
    bottom: calc((var(--nav-line-height) * 3) + var(--global-layout-pad));
}
.nav li{
    margin:0;
    padding:0;
}
.nav a{
    text-decoration: none;
    font-weight: 800;
    opacity:0.35;
    transition: all .35s linear;   
}
.nav a:hover{
    opacity:1;
}
.selected a{
    opacity:1;
}

.uilink{
    composes: uilink from '../../styles/global.css';
    width:100%;
}
@media screen and (max-width: 900px) { 
    .wrapper h1{
        vertical-align: top;
    }
    .mobileNav .closeBtn{
        top:calc(var(--global-layout-pad) + 2px);
        left:var(--global-layout-pad);
        padding:10px 10px 0px 10px;
    }
    
    .header{
        background: var(--color-black);
        margin: calc(0px - var(--global-layout-pad));
        z-index: 100;
        padding-top: var(--global-layout-pad);
        padding-right: var(--global-layout-pad);
        padding-left: var(--global-layout-pad);
        padding-bottom: calc(var(--global-layout-pad) / 2);
    }

    .wrapperCollapsed .nav{
        padding-top:0px;
        padding-bottom:0px;
        width:100%;
        position:fixed;
        transform:translateY(calc(0px - var(--window-height)));
        bottom: 0px;
        transition: all .65s cubic-bezier(0.19, 1, 0.22, 1);
        height: 0px;
        overflow: hidden;
        z-index: 1;
        background: var(--color-black);
    }

    .wrapperCollapsed.openNav .nav{
        background: var(--color-black);
        z-index: 1;
        width: 100%;
        padding-top: calc(100% + (var(--nav-line-height) * 3));
        padding-bottom: var(--global-element-pad);
        bottom: 0px;
        transform:translateY(0px);
        height: calc((var(--nav-line-height) * 3) + var(--global-element-pad));
        margin-left: calc(0px - var(--global-layout-pad));
        padding-left: var(--global-layout-pad);
    }
    
}