:root {
    --color-hilite: rgb(239, 113, 223);
    --color-grey: #ccc;
    --color-black: #333333;
    --color-white: #ffffff;
    --global-element-pad: 20px;
    --global-content-pad: 40px;
    --global-layout-pad:50px;
    --global-layout-pad-total:calc(var(--global-layout-pad) * 2);

    --serif-font: 'Playfair Display', serif;
    --sans-font: 'Raleway', sans-serif;


    /* layout */
    --nav-width:35vw;
    --content-width:65vw;
    --content-rightpad:20vw;
    --content-rightpad-extra:10vw;
    --header-height:62px;
    --window-height:768px;
    --negative-window-height:calc(0 - var(--window-height));

    --font-size-0: 1rem; /*16px*/
    --font-size-1: 1.125rem; /*18px*/
    --font-size-2: 1.5rem; /*24px*/
    --font-size-3: 2.25rem; /*36px*/
    --font-size-4: 3.375rem; /*54px*/
    --font-size-5: 5.063rem; /*81px*/
    --font-size-6: 7.594rem; /*121.5px*/

    --nav-font-size:calc(20px + (120 - 20) * ((100vw - 700px) / (1600 - 600)));
    --nav-line-height:calc(24px + (104 - 24) * ((100vw - 600px) / (1600 - 600)));
    --nav-desktop-closed:calc((var(--window-height) - (var(--header-height) + var(--global-layout-pad-total))));
    --mobile-breakpoint:900px;
  
}

@media screen and (max-width: 1440px) { 
  :root {
    --content-rightpad-extra:5vw;
     --nav-width:30vw;
     --content-width:70vw;
 }
}
@media screen and (max-width: 1200px) { 
  :root {
    --content-rightpad-extra:1vw;
    --nav-width:25vw;
    --content-width:75vw;
   }
}


@media screen and (max-width: 900px) { /*basically mobile*/
     :root {
      --global-element-pad: 10px;
      --global-content-pad: 20px;
      --global-layout-pad:30px;

      
        --nav-width:20vw;
        --content-width:calc(100vw - var(--global-element-pad));
        --content-rightpad-extra:0vw;



        --font-size-1: 1rem; /*16px*/
        --font-size-2: 1.125rem; /*18px*/
        --font-size-3: 1.5rem; /*24px*/
        --font-size-4: 2.25rem; /*36px*/
        --font-size-5: 3.375rem; /*54px*/
        --font-size-6: 5.063rem; /*81px*/
        --font-size-7: 7.594rem; /*121.5px*/

        --nav-font-size: var(--font-size-6);
        --nav-line-height: calc(var(--font-size-6) * .9);
    }
}
